import { Sidebar } from "../../components/sidebar/sidebar";
import { ApolloError, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Property, PropertyPledge, User, UserStatus } from "../../__generated__/graphql";
import ImgSample1 from '../../assets/img/properties/1.jpeg';
import { MUTATION_CONFIRM_PLEDGE, MUTATION_DELETE_PLEDGE } from "../../graphql/mutations/pledge";
import { GET_BALANCE } from "../../graphql/queries/get-balance";
import { QUERY_GET_PENDING_PLEDGES, QUERY_GET_PLEDGE_PROPERTY } from "../../graphql/queries/get-pending-pledges";
import { AppRoute } from "../../routes";
import { addCurencySubunits, removeCurrencySubunits, toCurrencyDisplay, toTokens, toTokensInt } from "../../utils/currency.util";
import { toPercentageDisplay } from "../../utils/string.util";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import CartSkeleton from "../../components/skeleton/cartskeleton";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import InformationSvg from "../../assets/svgs/information.svg";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { getPropertyImageUrl } from "../../utils/image.utils";
import CongratulationsPopup from "../../components/modal/congratulations-popup";
import { set } from "date-fns";
import Confetti from 'confetti-react';
import ImportantNoticeImg from "../../assets/svgs/shield-waring.svg";
import UnderMaintenance from "../../components/modal/under-maintenance";
import { KycCheck } from "../account/kyc/kyc";

export function CartPage() {
  const navigate = useNavigate();
  const [pledges, setPledges] = useState<PropertyPledge[]>([]);
  const [total, setTotal] = useState<number>();
  const [isTotalValid, setIsTotalValid] = useState<boolean>(false);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState('3ch');
  const [showCongratsPopup, setShowCongratsPopup] = useState<boolean>(false);
  const [isUnverifiedTotal, setIsUnverifiedTotal] = useState<boolean>(false);

  const { data: user, loading, error: loadingUser } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: 'network-only' });

  const [getPendingPledges, { data: getPendingPledgesResult, loading: loadingPendingPledges, error }] = useLazyQuery<{ getPendingPledges: PropertyPledge[] }>(QUERY_GET_PENDING_PLEDGES, {
    fetchPolicy: 'network-only',
    variables: {
      skip: 0,
      take: 10,
    }
  });

  const [getProperty, { error: getPledgeError }] = useLazyQuery<{ property: Property }>(QUERY_GET_PLEDGE_PROPERTY);
  const { data: balanceData, error: getBalanceError } = useQuery(GET_BALANCE, { fetchPolicy: 'network-only' });
  const [confirmMultiplePledges, { loading: loadingConfirmPledge, error: confirmPledgeError }] = useMutation<{ confirmPledge: PropertyPledge }>(MUTATION_CONFIRM_PLEDGE);
  const [deletePledge, { data: pledgeDeleted, loading: loadingDeletePledge, error: deletePledgeError }] = useMutation<{ deletePledge: PropertyPledge }>(MUTATION_DELETE_PLEDGE, {
    onCompleted: () => getPendingPledges()
  });

  const [errorMessage, setErrorMessage] = useState<string>();

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(getPledgeError);
  useAuthAndErrorHandling(getBalanceError);

  useEffect(() => {
    getPendingPledges();
  }, []);

  useEffect(() => {
    if (pledgeDeleted) {
      setPledges((prevPledges) => prevPledges.filter((pledge) => pledge.id !== pledgeDeleted.deletePledge.id));
    }
    // Optionally refetch pledges if needed
    getPendingPledges();
  }, [pledgeDeleted]);

  useEffect(() => {
    const pledgeItems = getPendingPledgesResult?.getPendingPledges || [];

    if (pledgeItems.length > 0) {
      const propertyPromises = pledgeItems.map((item) =>
        getProperty({
          variables: { id: item.property.id },
        }).then((result) => {
          if (result.data && result.data.property) {
            return {
              ...item,
              property: result.data.property,
            };
          }
          return item;
        })
      );

      Promise.all(propertyPromises)
        .then((updatedPledges) => {
          setPledges(updatedPledges);
        })
        .catch((error) => {
          console.error("Error fetching properties:", error);
        });
    }
  }, [getPendingPledgesResult, getProperty]);


  useEffect(() => {
    const totalAmount = pledges.reduce((acc, item) => acc + item.amount, 0);
    setTotal(totalAmount);

    // Set the new cart count
    localStorage.setItem('cartCount', toTokensInt(totalAmount).toString());

  }, [pledges]);

  useEffect(() => {
    if (balanceData) {
      const balance = balanceData.getBalance;
      if (user && user.me.status === UserStatus.Unverified && total) {
        setIsUnverifiedTotal((total / 100 / 100) > 1);
      }

      if (total && balance < total) {
        setIsTotalValid(false);
        setErrorMessage('Insufficient balance');
      } else {
        setIsTotalValid(true);
        setErrorMessage(undefined);
      }
    }

    setIsConfirmingPayment(false);
  }, [balanceData, total]);

  function onPledgeAmountChange(pledgeId: string, value: string) {
    let numericValue = parseInt(value, 10);
    setInputWidth(`${Math.max(3, value.length)}ch`);

    if (isNaN(numericValue) || numericValue < 1) {
      numericValue = 1; // Ensure minimum value is 1
    }

    setPledges((prev) => {
      const newPledges = prev.map((item) => {
        const pledge = { ...item };
        if (pledge.id === pledgeId) {
          pledge.amount = addCurencySubunits(numericValue * 100)!;
        }
        return pledge;
      });
      return newPledges;
    });
  }

  function incrementAmount(pledgeId: string) {
    setPledges((prev) => {
      const newPledges = prev.map((item) => {
        const pledge = { ...item };
        if (pledge.id === pledgeId) {
          const currentTokens = toTokensInt(pledge.amount);
          pledge.amount = addCurencySubunits(currentTokens + 1)!;
        }
        return pledge;
      });
      return newPledges;
    });
  }

  function decrementAmount(pledgeId: string) {
    setPledges((prev) => {
      const newPledges = prev.map((item) => {
        const pledge = { ...item };
        if (pledge.id === pledgeId) {
          const currentTokens = toTokensInt(pledge.amount);
          pledge.amount = addCurencySubunits(Math.max(currentTokens - 1, 1))!;
        }
        return pledge;
      });
      return newPledges;
    });
  }

  async function pay() {
    if (pledges.length > 0) {
      try {
        const pledgesInput = pledges.map((item) => ({
          id: item.id,
          amount: item.amount,
        }));

        await confirmMultiplePledges({
          variables: { pledges: pledgesInput },
        });

        setShowCongratsPopup(true);
        localStorage.setItem('cartCount', '0');
      } catch (e) {
        setErrorMessage((e as ApolloError).message);
      }
    }
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />
      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />
      <Sidebar />
      {showCongratsPopup && (<>

        <CongratulationsPopup
          open={showCongratsPopup}
          properties={pledges.map((item) => item.property.title)}
          onClose={() => { setShowCongratsPopup(false); navigate(AppRoute.Portfolio); }}
        />
      </>
      )}
      <section className="content cart">
        <h1 className="mb-4 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">Cart ({pledges.length})</h1>
        {loadingPendingPledges ? (
          <CartSkeleton />
        ) : (
          <>
            {pledges.length === 0 && <p>No items in cart</p>}
            {pledges.length > 0 && (
              <div className="flex flex-wrap gap-6 cart-content m-flex-col">
                <div className="flex flex-col gap-6 flex-3 card rounded-3xl">


                  {pledges.map((item) => (
                    <div key={item.id} className="flex flex-wrap bg-[#F8F8F9] p-4 rounded-3xl relative">
                      {/* Close Button */}
                      <button
                        className="absolute -top-1 -right-1 cursor-pointer transition ease-in duration-100 rounded-full p-2 pb-2.5 bg-[#D9DBE0] text-gray-500 hover:text-black flex items-center justify-center disabled:opacity-50"
                        style={{ width: '24px', height: '24px' }}
                        onClick={() => {
                          deletePledge({ variables: { pledgeId: item.id } })
                          const currentCount = localStorage.getItem('cartCount');
                          const newCount = currentCount ? parseInt(currentCount) - toTokensInt(item.amount) : 0;
                          localStorage.setItem('cartCount', newCount.toString());
                        }}
                        disabled={loadingDeletePledge}
                      >
                        x
                      </button>
                      <div className="flex flex-wrap items-center justify-between w-full cart-item m-flex-col">
                        <div className="flex flex-wrap gap-4">
                          {item.property?.images && item.property.images.length > 0 && (
                            <div className="cart-item-image">
                              <img className="m-w-full" src={getPropertyImageUrl(item.property.id, item.property?.images[0])} alt="Property" />
                            </div>
                          )}
                          <div className="cart-item-details">
                            <h3 className="mb-6 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
                              {item.property?.title}
                            </h3>
                            <div className="flex gap-4 cart-stats">
                              <div className="cart-stat">
                                <p className="text-[#8B97B0] text-base">Monthly Rent</p>
                                <h4 className="-mt-2 text-base font-bold leading-normal tracking-normal text-deep-blue">
                                  {toCurrencyDisplay(item.property?.rents && item.property?.rents.length ? item.property?.rents[0].rent : NaN) || '-'}
                                </h4>
                              </div>
                              <div className="cart-stat">
                                <p className="text-[#8B97B0]">Appreciation</p>
                                <h4 className="-mt-2 text-base font-bold leading-normal tracking-normal text-deep-blue">
                                  {toPercentageDisplay(item.property?.financials && item.property?.financials.length ? item.property.financials[0].annualAppreciation : NaN) || '-'}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Token Amount Section */}
                        <div className="flex items-center px-4 py-2 mt-4 mb-2 bg-white rounded-lg">
                          <div className="text-base text-[#14223D] flex items-center font-medium">
                            Tokens
                          </div>
                          <div className="h-6 border-l border-[rgba(177,177,177,0.24)] ml-4"></div>
                          <div className="flex items-center ml-4">
                            <input
                              type="number"
                              value={toTokensInt(item.amount)}
                              min={1}
                              onChange={(e) => onPledgeAmountChange(item.id, e.currentTarget.value)}
                              className="text-base font-semibold text-center bg-transparent border-none appearance-none focus:outline-none"
                              style={{
                                width: inputWidth,
                              }}
                            />
                            {/* <div className="flex flex-col ml-1">
                              <button
                                className="leading-none text-gray-500 hover:text-black"
                                onClick={() => incrementAmount(item.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 4.5l-4 4h8l-4-4z" />
                                </svg>
                              </button>
                              <button
                                className="leading-none text-gray-500 hover:text-black"
                                onClick={() => decrementAmount(item.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 11.5l4-4H4l4 4z" />
                                </svg>
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col flex-1 gap-4 p-6 bg-white border shadow-sm cart-total card rounded-xl">
                  <h2 className="text-2xl font-bold text-[#14223D]">Checkout</h2>
                  <hr className="my-4 border-t border-gray-300" />
                  <div className="flex items-center justify-between">
                    <div className="text-[#14223D] font-bold text-lg">Balance (Tokens)</div>
                    <div className="text-[#8B97B0]">{balanceData ? toTokens(balanceData?.getBalanceObject.getBalance) : '0'}</div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div className="text-[#14223D] font-bold text-lg">Total (Tokens)</div>
                    <div className="text-[#8B97B0]">{total ? toTokens(total) : '0'}</div>
                  </div>
                  <hr className="my-4 border-t border-gray-300" />
                  {(error) && (
                    <div className="flex flex-col items-start mt-4 text-red-500">
                      <div className="flex items-center mb-2">
                        <img className="w-5 h-5 mr-2" src={InformationSvg} alt="Information" />
                        <span className="font-semibold">{error.message}</span>
                      </div>
                      <p className="text-sm font-semibold text-[#8B97B0]">An error occurred while processing your request. Please try again later.</p>
                    </div>
                  )}
                  {confirmPledgeError && (
                    <div className="flex flex-col items-start mt-4 text-red-500">
                      <div className="flex items-center mb-2">
                        <img className="w-5 h-5 mr-2" src={InformationSvg} alt="Information" />
                        <span className="font-semibold">{confirmPledgeError.message}</span>
                      </div>
                      <p className="text-sm font-semibold text-[#8B97B0]">An error occurred while processing your request. Please try again later.</p>
                    </div>
                  )}
                  {deletePledgeError && (
                    <div className="flex flex-col items-start mt-4 text-red-500">
                      <div className="flex items-center mb-2">
                        <img className="w-5 h-5 mr-2" src={InformationSvg} alt="Information" />
                        <span className="font-semibold">{deletePledgeError.message}</span>
                      </div>
                      <p className="text-sm font-semibold text-[#8B97B0]">An error occurred while processing your request. Please try again later.</p>
                    </div>
                  )}

                  {!isTotalValid && (
                    <div className="flex flex-col items-start mt-4 text-red-500">
                      <div className="flex items-center mb-2">
                        <img className="w-5 h-5 mr-2" src={InformationSvg} alt="Information" />
                        <span className="font-semibold">Insufficient Balance</span>
                      </div>
                      <p className="text-sm font-semibold text-[#8B97B0]">You need to deposit tokens into the Vault to start investing with PropNerd.</p>
                      <Link to={AppRoute.Vault} className="text-sm text-[#14223D] mt-2 underline">
                        Click here to Go to Vault
                      </Link>
                    </div>
                  )}
                  {user && user.me.status === UserStatus.Unverified && total && isUnverifiedTotal && (
                    <div className="flex flex-col items-start mt-4 text-red-500">
                      <div className="flex items-center mb-2">
                        <img className="w-5 h-5 mr-2" src={InformationSvg} alt="Information" />
                        <span className="font-semibold">Verification Needed</span>
                      </div>
                      <p className="text-sm font-semibold text-[#8B97B0]">You need to verify your account to comply with our KYC requirements to start investing with PropNerd.</p>
                      <Link to={AppRoute.Vault} className="text-sm text-[#14223D] mt-2 underline">
                        Click here to Start Verification
                      </Link>
                    </div>
                  )}
                  {user && user.me.status === UserStatus.Unverified && (
                    <div className="rounded-lg border-1.5 border-[#F8D5AE] max-w-md bg-[#FDF5EC]  p-4 flex flex-col gap-8 mb-6">
                      <div className="flex gap-2">
                        <img
                          src={ImportantNoticeImg}
                          alt="Shield warning"
                        />{" "}
                        <p className="font-bold text-[#14223D] ">
                          Important Note
                        </p>{" "}
                      </div>
                      <div className="flex-col text-center">
                        <p className="text-[#768195] mb-2">
                          To deposit more than{" "}
                          <span className="text-[#14223D] font-bold ">
                            £100
                          </span>,or make an investment, we require you to complete our{" "}
                          <span className="text-[#14223D] font-bold ">
                            KYC verification
                          </span>. This quick process ensures your account's
                          security and complies with regulations,
                          allowing you to seamlessly invest with peace
                          of mind.
                        </p>
                        <div
                          className="p-6 btn"
                          onClick={() => navigate(AppRoute.Vault + '#verify')}
                        >
                          Verify Now
                        </div>
                      </div>
                    </div>
                  )}
                  {!isConfirmingPayment && (
                    <button
                      className={`btn mt-6 py-6 px-6 rounded-md w-full text-white ${loadingPendingPledges || !isTotalValid ? 'bg-gray-300 cursor-not-allowed' : 'bg-[#14223D] hover:bg-[#0f1a2d]'}`}
                      disabled={loadingPendingPledges || !isTotalValid || (user && user.me.status === UserStatus.Unverified)}
                      onClick={() => setIsConfirmingPayment(true)}
                    >
                      Pay Now!
                    </button>
                  )}
                  {isConfirmingPayment && isTotalValid && (
                    <>
                      <p className="mt-4 text-gray-700">We're about to deduct <b>{toTokens(total!)} token</b> from your wallet and apply it to the selected properties as you've set.</p>
                      <p className="mt-2 text-gray-700">To proceed, please click the 'Confirm' btutton below.</p>
                      <div className="flex gap-4 mt-4">
                        <button className="w-full py-4 btn btn-secondary" onClick={() => setIsConfirmingPayment(false)}>
                          Cancel
                        </button>
                        <button className="w-full py-4 btn btn-primary" disabled={loadingConfirmPledge} onClick={pay}>
                          {loadingConfirmPledge ? 'Processing...' : 'Confirm'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
}
