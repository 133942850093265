import { useLazyQuery } from '@apollo/client';
import { FormEvent, useState } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { ReactComponent as SvgLogo } from '../../assets/img/common/logo.svg';
import { GlobalErrorHandler } from '../error/global_error.component';
import { VERIFY_PROMO_CODE } from '../../graphql/queries/coupon';
import CongratulationsPopup from './congratulations-popup';
import { Modal } from './modal';
import ConfettiCannon from '../../assets/img/congrats.png'; // Ensure this path is correct
import Confetti from 'confetti-react';
import UnderMaintenance from './under-maintenance';



interface PromoModalProps {
  open: boolean;
  onClose: () => void;
}

export function PromoModal({ open, onClose }: PromoModalProps) {
  const [promoCode, setPromoCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [infoMessage, setInfoMessage] = useState<string>('');
  const [showCongrats, setShowCongrats] = useState<boolean>(false);

  const [verifyPromoCode, { data, error }] = useLazyQuery(VERIFY_PROMO_CODE);

  const handlePromoCodeVerification = (e: FormEvent) => {
    e.preventDefault();
    verifyPromoCode({ variables: { code: promoCode } })
      .then((response) => {
        if (response.data?.verifyPromoCode) {
          setInfoMessage(response.data.verifyPromoCode.message);
          setShowCongrats(true);
        } else {
          setErrorMessage('Invalid promo code');
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
        console.error(err);
      });
  };

  const handleContinue = () => {
    setShowCongrats(false);
    onClose();
  };

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  return (
    <>
      <GlobalErrorHandler />
      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />
      {/* Promo Code Modal wrapped in Modal */}
      {open && (
        <Modal title="" onClose={onClose}>
          {showCongrats ? (
            <>
              <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, pointerEvents: 'none' }}>
                <Confetti
                  numberOfPieces={150}
                  recycle={false}
                  gravity={0.3}
                  initialVelocityY={15}
                  width={window.innerWidth}
                  height={window.innerHeight}
                  confettiSource={{ x: window.innerWidth / 2, y: 230, w: 10, h: 0 }}
                  colors={['#FF00FF', '#00FFFF', '#FFFF00', '#FF0000', '#00FF00']}
                />
              </div>
              <Dialog className='rounded-lg' open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogContent className="mx-6 rounded-2xl text-center my-8">
                  <img src={ConfettiCannon} className="max-w-52 mx-auto mb-4 mt-2" alt="Confetti Cannon" />
                  <h1 className="font-sans text-deep-blue font-bold leading-normal text-4xl tracking-wide my-4">Congratulations!</h1>
                  <p className="text-lg mb-6 text-center px-10">
                    {infoMessage}
                  </p>
                  <button
                    className="mt-10 rounded-lg bg-[#14223d] w-full hover:bg-[#0f1c33] text-white font-sans tracking-normal leading-normal text-lg px-12  mb-4 py-4"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </DialogContent>
              </Dialog>
            </>
          ) : (
            <>
              <SvgLogo className="mx-auto text-center max-w-52" />
              <div className='mt-8 mb-10 max-w-[40rem]'>
                <h3 className="font-sans text-center text-md md:text-xl font-bold leading-normal tracking-wide text-primary">
                  Unlock Your Exclusive Offer
                </h3>
                <h4 className="font-sans text-center text-md md:text-xl font-bold leading-normal tracking-wide text-[#6E7B94] md:px-20">
                  Apply your promo code below and unlock exclusive benefits!
                </h4>
              </div>

              <form onSubmit={handlePromoCodeVerification} className="promo-form mt-4">
                <label htmlFor="promoCode" className=" block text-lg text-bold">
                  Promo Code
                </label>
                <input
                  type="text"
                  id="promoCode"
                  value={promoCode}
                  onChange={(e) => {
                    setPromoCode(e.target.value);
                    setErrorMessage('');
                  }}
                  placeholder="Enter Promo Code"
                  className="mt-2 bg-white text-[#14223d] font-sans tracking-normal leading-normal text-sm md:text-lg px-2 rounded-lg border-[1px] border-solid py-3 w-[100%]"
                />
                {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <p className="text-red-500">{errorMessage}</p>}

                <button
                  type="submit"
                  color="primary"
                  className="mt-10 bg-[#14223d] hover:bg-[#0f1c33] text-white text-bold font-sans tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg py-3 w-[100%]"
                >
                  Apply
                </button>
              </form>

              <button
                onClick={onClose}
                className="mt-2 bg-white hover:bg-[#E5E7EB] text-[#14223d] font-sans text-bold tracking-normal leading-normal text-sm md:text-lg px-12 rounded-lg border-[1px] border-solid py-3 w-[100%]"
              >
                Skip
              </button>
            </>
          )
          }

        </Modal>
      )}
    </>
  );
}

export default PromoModal;
