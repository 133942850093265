import { Sidebar } from "../../components/sidebar/sidebar";
import { KycCheck } from "../account/kyc/kyc";

export function RewardsPage() {
  return (
    <>
      <KycCheck />
      <Sidebar />

      <section className="content rewards">
        <h1 className="font-sans text-deep-blue tracking-normal text-3xl mb-8 font-bold leading-normal">Rewards</h1>
        <div className="card flex justify-between">
          <div className="flex flex-1 flex-col gap-2 border-r ">
            <p className="text-lg text-gray-800 ">Total rewards earned</p>
            <h3 className="text-4xl  font-semibold font-sans text-deep-blue tracking-normal leading-normal">£0</h3>
            <a href="#view-balance" className="text-sm  text-blue-500 ">view current balance &gt;</a>
          </div>
          <table className="flex-1 w-full ">
            <tbody>
              <tr>
                <td><span className="icon"></span></td>
                <td>Cashback</td>
                <td>£0</td>
              </tr>
              <tr>
                <td><span className="icon"></span></td>
                <td>Referrals</td>
                <td>£0</td>
              </tr>
              <tr>
                <td><span className="icon"></span></td>
                <td>Promotions</td>
                <td>£0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  )
}